<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('news')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('news')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-searchbar v-bind:placeholder="$t('search')" />
      <ion-item>
        <ion-label>Filter</ion-label>
        <ion-select
          multiple
          name="Filter"
          v-bind:placeholder="$t('filter_choose')"
          v-bind:cancel-text="$t('cancel')"
          v-bind:ok-text="$t('save')"
        >
          <ion-select-option>Hallo 1</ion-select-option>
          <ion-select-option>Hallo 2</ion-select-option>
        </ion-select>
      </ion-item>

      <div v-if="loading" class="loading">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
        <h4>Lädt</h4>
      </div>

      <div v-if="error" class="loading">
        {{ error }}
      </div>

      <template v-for="article in news" :key="article.id">
        <ion-card>
          <router-link :to="'/tabs/news/' + article.id">
            <ion-card-header>
              <ion-card-title class="news-title">{{ article.titel }}</ion-card-title>
              <ion-card-subtitle>{{ new Date(article.datum).toLocaleDateString('de-DE') }} – {{ article.untertitel }}</ion-card-subtitle>
            </ion-card-header>
          </router-link>
          <ion-card-content>
            <ion-badge color="primary">{{ article.kategorie.titel }}</ion-badge>
          </ion-card-content>
        </ion-card>
      </template>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { directus } from '../main'

export default {
  name: 'News',
  components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner },
  data: function() {
    return {
      loading: false,
      error: null,
      news: null,
    }
  },
  mounted() {
    this.error = this.news = null
    this.loading = true

    async function getNews() {
        // ... before fetching items
        return await directus.items('news').read({
          fields: ['titel', 'untertitel', 'datum', 'kategorie.*', 'id'],
          filter: {
            status: {
              "_eq": "published"
            }
          }
        });
      }
      getNews().then(news => {
        this.news = news.data;
        this.loading = false;
      }).catch(err => {
        this.error = err.toString();
      })
    }
}
</script>

<style scoped>
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
}
  .news-title {
    font-size: 18px;
  }
</style>
