
import { IonPage, IonHeader, IonToolbar, IonTitle, IonSearchbar, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge } from '@ionic/vue';
import HeaderOne from '@/components/HeaderOne.vue';
import { directus } from '../main'

export default {
  name: 'News',
  components: { IonHeader, IonToolbar, IonTitle, IonPage, HeaderOne, IonSearchbar, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBadge, IonSelect, IonSelectOption, IonItem, IonContent, IonLabel, IonSpinner },
  data: function() {
    return {
      loading: false,
      error: null,
      news: null,
    }
  },
  mounted() {
    this.error = this.news = null
    this.loading = true

    async function getNews() {
        // ... before fetching items
        return await directus.items('news').read({
          fields: ['titel', 'untertitel', 'datum', 'kategorie.*', 'id'],
          filter: {
            status: {
              "_eq": "published"
            }
          }
        });
      }
      getNews().then(news => {
        this.news = news.data;
        this.loading = false;
      }).catch(err => {
        this.error = err.toString();
      })
    }
}
